
              @import "@/assets/css/variables/variables.scss";
            

@import '@/assets/css/pages/deposit/internationalAndAu.scss';
.upload_label {
  /deep/ .el-form-item__label {
    top: -0px !important;
  }
  /deep/ .el-form-item__content {
    margin-top: 13px;
  }
}
.wise_form {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .clearfix {
    margin-bottom: 40px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  .width_img {
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.text_2rem {
  text-indent: 2rem;
}
